import React, { useEffect, useState } from 'react';
import { Paginate } from '../pagination/pagination';
import { Select } from '../form/form';

export const perPageList = [
  {
    id: '7',
    name: '7 / Página',
  },
  {
    id: '20',
    name: '20 / Página',
  },
  {
    id: '50',
    name: '50 / Página',
  }
]

export const List = ({
  header,
  data,
  listCustom = () => null,
  sort={},
  noData = 'Sem informação',
  paginate = false,
  perPageOptions=perPageList,
  actionPaginate=()=> null,
  handlePerPage=()=> null,
  handleSort=()=> null,
}) => {
  const [listState, setListState] = useState([]);

  useEffect(()=>{
    setListState(data)
  }, [data])

  const sortType = (e) => {
    switch (e) {
      case 'up':
        return 'down';
      case 'down':
        return 'normal';
      case 'normal':
        return 'up';
      default:
        return 'up';
    }
  }

  const sortHandle = ({sortInfo, sortColumn}) => {
    if (sortColumn) {
      handleSort(sortInfo)
    }
  }

  return (
    <>
    <div className="box-scrool">
      <table className="list-box">
        <thead>
          <tr>
            {header?.map((head) => {
              return (
                <td 
                  key={head.column}
                  className={`${head.className} ${head.sort?`list-box-header-sort ${sort?.column === head.column?`sort-${sort?.type}`: ''}`:''}`} 
                  onClick={()=> 
                    sortHandle({
                      sortColumn: head.sort, 
                      sortInfo: {
                        column: head.column, 
                        type: sort?.column === head.column ? sortType(sort.type) : 'up'
                      }
                    })
                  }
                >
                  {head.text}
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length ? (
            listState?.map((container, i) => {
              listCustom(container, i, data);
              return (
                <tr key={container.id ? container.id : i}>
                  {header.map((head) => {
                    return (
                      <td
                        className={head.className}
                        key={`${container.id ? container.id : i}-${
                          head.column
                        }`}
                      >
                        {container[head.column]
                          ? container[head.column]
                          : ''}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={header?.length} className="text-center">
                {noData}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    {
      paginate?
      <PaginateList
        paginate={paginate}
        perPageOptions={perPageOptions}
        actionPaginate={actionPaginate}
        handlePerPage={handlePerPage}
      />
      :null
    }
    </>
  );
};

export const PaginateList = ({
  paginate,
  perPageOptions = [],
  actionPaginate = ()=> null,
  handlePerPage = ()=> null,
}) => {

  const selectedValue = e => {
    const filter = perPageOptions.filter(f=> String(f.id) === String(e))
    if (filter.length) {
      return filter[0]
    } else {
      return perPageOptions.length?perPageOptions[0]:{}
    }
  }

  return (
  <div className="list-box-actionsfooter">
    <div className="list-box-registros">
      {paginate.totalElements} {paginate.totalElements === 1 ? 'Registro' : 'Registros'}
    </div>
    <div className="list-box-paginacao">
      <Paginate
        data={paginate}
        action={actionPaginate}
      />
    </div>
    <div className="list-box-itensperpage">
      <Select
        name='projeto'
        label=''
        options={perPageOptions}
        selected={selectedValue(paginate?.perPage)}
        action={handlePerPage}
        selectedItem={false}
      />
    </div>
  </div>
  )
}