import { IcoComentarios, IcoDados, IcoEmpresa, IcoGrupos } from "../components"

export const menuItens = () => {
  return [
    { 
      id: "Resumodoprojeto", 
      label: "Resumo do projeto", 
      go: "/resumoprojeto", 
      icon: <IcoDados /> 
    },
    { 
      id: "empresas", 
      label: "Empresas", 
      icon: <IcoEmpresa />,
      sub: [
        { 
          id: "CSAT", 
          label: "CSAT", 
          go: "/csat",
        },
        { 
          id: "NPS", 
          label: "NPS", 
          go: "/nps",
        },
        { 
          id: "CSATConcorrentes", 
          label: "CSAT: comparação com concorrentes", 
          go: "/csatconcorrentes",
        },
        { 
          id: "NPSConcorrentes", 
          label: "NPS: comparação com concorrentes", 
          go: "/npstconcorrentes",
        }
      ]
    },
    { 
      id: "lojas", 
      label: "Lojas", 
      icon: <IcoGrupos />,
      sub: [
        { 
          id: "Resumodosgrupos", 
          label: "Resumo dos grupos", 
          go: "/resumogrupos",
        },
        { 
          id: "RankingClienteCSAT", 
          label: "CSAT dos estabelecimentos", 
          go: "/csatestabelecimentos",
        },
        { 
          id: "RankingClienteNPS", 
          label: "NPS dos estabelecimentos", 
          go: "/npsestabelecimentos",
        },
        { 
          id: "RankingCSAT", 
          label: "CSAT dos estabelecimentos: comparação com concorrentes", 
          go: "/csatestabelecimentosconcorrentes",
        },
        { 
          id: "RankingNPS", 
          label: "NPS dos estabelecimentos: comparação com concorrentes", 
          go: "/npsestabelecimentosconcorrentes",
        },
        { 
          id: "Indicadores", 
          label: "Indicadores", 
          go: "/indicadores",
        },
        { 
          id: "“AnáliseSWOT", 
          label: "Análise SWOT", 
          go: "/analiseswot",
        }
      ]
    },
    { 
      id: "Comentarios", 
      label: "Comentários", 
      go: "/comentarios", 
      icon: <IcoComentarios /> 
    }
  ]
}