import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, IcoLogOut, IcoMenu, IcoUser, IcoUser2 } from '../components'
import { logOut, setSidebarLeft, setSidebarRigth } from './redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const {titlePage, usuario, sidebarLeft, sidebarRight} = useSelector(state => state.layoutState)

  const logout = e => {
    dispatch(logOut())
  }
  
  return (
    <header id='box-header'>
      <button
        className={sidebarLeft ? 'btn-menu open' : 'btn-menu'}
        onClick={() => dispatch(setSidebarLeft(!sidebarLeft))}
      >
        <IcoMenu />
      </button>
      <div className='box-logo'>
        {/* <div></div> <h1>{titlePage}</h1> */}
      </div>
      <button
        className={sidebarRight ? 'btn-menu open' : 'btn-menu'}
        onClick={() => dispatch(setSidebarRigth(!sidebarRight))}
      >
        <IcoUser2 />
      </button>
      <div className={`box-user-info ${sidebarRight?'open-box':''}`}>
        <p dangerouslySetInnerHTML={{__html: `Olá <strong>${usuario?.usuarioNome}</strong>, da empresa <strong>${usuario?.nomeEmpresa}</strong>`}}></p>
        <Button
          type='link'
          onClick={()=> logout()}
        >
          Sair
          <IcoLogOut />
        </Button>
      </div>
    </header>
  )
}
