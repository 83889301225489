import { dateCustom } from '../../../../components';
import { graficoCustom, numberZero, paramsApi } from '../../../../helpers';
import api from '../../../../helpers/api';
import { setFiltered } from '../../../../layout/redux/layoutActions';
const labelsItens = ['Geral', 'Preço', 'Produto', 'Atendimento', 'Loja']
const listasMock = {
  grafico: {
    data: labelsItens.map((l)=> ({ 
      name: l, 
      anterior: Math.floor(Math.random() * (100 - 0 + 1)) + 0,
      atual: Math.floor(Math.random() * (100 - 0 + 1)) + 0
    })),
    labels: {
      anterior: 'NPS anterior',
      atual: 'NPS atual',
    },
    analiseGrafica: 'Esta tabela 1 apresenta uma análise detalhada dos dados. Com base nos dados apresentados, podem ser identificadas tendências, padrões e áreas de melhoria para ajudar a orientar suas decisões e estratégias futuras. As regiões fazem uma comparação clara dos grupos de loja.',
  },
  grafico1: {
    data: labelsItens.map((l)=> ({ 
      name: l, 
      anterior: Math.floor(Math.random() * (100 - 0 + 1)) + 0,
      atual: Math.floor(Math.random() * (100 - 0 + 1)) + 0
    })),
    labels: {
      anterior: 'NPS anterior',
      atual: 'NPS atual',
    },
    analiseGrafica: 'Esta tabela 2 apresenta uma análise detalhada dos dados. Com base nos dados apresentados, podem ser identificadas tendências, padrões e áreas de melhoria para ajudar a orientar suas decisões e estratégias futuras. As regiões fazem uma comparação clara dos grupos de loja.',
  },
  graficosimples: {
    data: labelsItens.map((l)=> ({ 
      name: l, 
      atual: Math.floor(Math.random() * (100 - 0 + 1)) + 0
    })),
    labels: {
      atual: 'NPS',
    },
    analiseGrafica: 'Esta tabela apresenta uma análise detalhada dos dados. Com base nos dados apresentados, podem ser identificadas tendências, padrões e áreas de melhoria para ajudar a orientar suas decisões e estratégias futuras. As regiões fazem uma comparação clara dos grupos de loja.',
  },
}

export const setTotalAvaliacao = payload => ({
  type: 'INFO_GRAFICO_NPSCLIENTE_TOTAL_AVALIACAO',
  payload
})


export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_NPSCLIENTE',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_NPSCLIENTE',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_NPSCLIENTE',
})


export const setGrafico = payload => ({
  type: 'INFO_GRAFICO_NPSCLIENTE',
  payload
})

export const setGraficoLoad = payload => ({
  type: 'LOAD_GRAFICO_NPSCLIENTE',
  payload
})

export const carregarGrafico = ({projetoID, empresaId, params, typeSelect}) => {
  return dispatch => {
    dispatch(setGraficoLoad(true))
    let paramsUrl = paramsApi({
      ...params, 
      projetoId: projetoID,
      empresaId: empresaId,
    })
    api.get(`/front/GetNpsCliente${paramsUrl}`)
    .then(result => {
      const resultado = graficoCustom(result.data)
      dispatch([
        setGrafico(resultado),
        setTotalAvaliacao(result.data.totalAvaliacoes),
        setGraficoLoad(false)
      ])
    })
    .catch(err => {
      console.error(err);
      dispatch([
        setGrafico([]),
        setTotalAvaliacao(0),
        setGraficoLoad(false)
      ])
    });
  }
}

export const filterHandle = ({projetoID, empresaId, params, typeSelect, filtersProvisorio}) => {
  const paramsCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  return dispatch => {
    let paramsFilter = filtersCustom({params: paramsCustom, typeSelect})
    dispatch(setGraficoLoad(true))
    dispatch([
      setFiltered(paramsCustom),
      carregarGrafico({projetoID, empresaId, params: paramsFilter, typeSelect})
    ])
  }
}

export const filtersCustom = ({params, typeSelect}) => {
  let paramsFilter = {}
  if (typeSelect?.id === 'Ciclo') {
    if (params?.ciclo?.mesAno) {
      paramsFilter = {
        comparacao: params.compararCiclos.id === 'ativar'?true:false,
        intervalo: `${params.ciclo.ano}-${params.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(params.dataInicial).data} ${dateCustom(params.dataFinal).data}`,
    }
  }
  return paramsFilter
}