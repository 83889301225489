const initialState = {
  senhaVisivel: {},
  login: {
    email: '',
    senha: ''
  },
  esqueciSenha: {
    email: ''
  },
  recuperarSenha: {
    senha: '',
    senhaConfirmar: ''
  },
  erro: {},
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SENHA_VISIVEL':
      return { ...state, senhaVisivel: {...state.senhaVisivel, [payload.name]: payload.value} }
    case 'LOGIN_CHANGE_INFO':
      return { ...state, login: {...state.login, [payload.name]: payload.value} }
    case 'LOGIN_CHANGE_ESQUECI':
      return { ...state, esqueciSenha: {...state.esqueciSenha, [payload.name]: payload.value} }
    case 'LOGIN_CHANGE_RECUPERAR':
      return { ...state, recuperarSenha: {...state.recuperarSenha, [payload.name]: payload.value} }
    case 'LOGIN_ERROR':
      return { ...state, erro: {...state.erro, ...payload} }
    default:
      return state
  }
}
