import React, { useState } from 'react'
import { Button } from '../button/button'
import { IcoArrowLeft, IcoArrowRigth } from '../icon/icon'

const textDefault = {
  text: '{{pageNumber}} of {{totalPages}} - {{totalElements}} registers',
  next: <IcoArrowRigth />,
  before: <IcoArrowLeft />,
  reload: 'Update'
}

export function Paginate({ data = { pageNumber: 1, totalPages: 1, totalElements: 0 }, action, text = textDefault }) {
  const [paginateTemp, setPaginateTemp] = useState(String(data.pageNumber ? data.pageNumber : 1))

  const changePaginate = event => {
    setPaginateTemp(event.target.value)
  }

  const reloadPaginate = event => {
    if (event || event === 0) {
      if (event > 0 && event <= data.totalPages) {
        setPaginateTemp(event)
        action(event)
      } else {
        setPaginateTemp(data.pageNumber)
      }
    } else if (paginateTemp >= 1 && paginateTemp <= data.totalPages) {
      action(Number(paginateTemp))
    } else {
      setPaginateTemp(data.pageNumber)
    }
  }

  return (
    <div className='pagination-custom'>
      {
        data.totalElements > 0 ?
        <>
          <Button
            type='link'
            color='primary'
            onClick={() => reloadPaginate(data.pageNumber - 1)}
            disabled={data.pageNumber <= 1 ? true : false}
          >
            {text.before}
          </Button>
          <div className='page-item'>
            <input type='number' name='paginate' value={paginateTemp} onChange={event => changePaginate(event)} onBlur={(event) => reloadPaginate(event.target.value)} />
            <span>/ {data.totalPages}</span>
          </div>

          <Button
            type='link'
            color='primary'
            onClick={() => reloadPaginate(data.pageNumber + 1)}
            disabled={data.pageNumber >= data.totalPages ? true : false}
          >
            {text.next}
          </Button>
        </>
        : <></>
      }
    </div>
  )
}

export const PaginateTotal = ({
  total,
  totalPerPage
}) => {
  const totalPage = total / totalPerPage
  if (Number.isInteger(totalPage)) {
    return totalPage
  } else {
    return parseInt(total / totalPerPage) + 1
  }
}