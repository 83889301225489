import { dateCustom } from '../../../components';
import { paramsApi } from '../../../helpers';
import api from '../../../helpers/api';
import { filteredItens } from '../../../layout/redux/layoutActions';

export const setInfoTotalAvaliacao = payload => ({
  type: 'DADOS_PROJETO_TOTAL_AVALIACAO',
  payload
})

export const setListarDadosProjeto = payload => ({
  type: 'LIST_DADOS_PROJETO',
  payload
})

export const setPaginateDadosProjeto = payload => ({
  type: 'PAGINATE_DADOS_PROJETO',
  payload
})


export const setFiltersProvisorio = payload => ({
  type: 'INFO_FILTERS_DADOS_PROJETO',
  payload
})

export const putFiltersProvisorio = payload => ({
  type: 'CHANGE_FILTERS_DADOS_PROJETO',
  payload
})

export const cleanFiltersProvisorio = () => ({
  type: 'CLEAN_FILTERS_DADOS_PROJETO',
})


export const setLoadList = payload => ({
  type: 'LOAD_LIST_DADOS_PROJETO',
  payload
})

export const filtersCustom = ({filters, typeSelect}) => {
  let paramsFilter = {}
  if (typeSelect?.id === 'Ciclo') {
    if (filters?.ciclo?.mesAno) {
      paramsFilter = {
        comparacao: filters.compararCiclos.id === 'ativar'?true:false,
        intervalo: `${filters.ciclo.ano}-${filters.ciclo.mes}`,
      }
    }
  }
  if (typeSelect?.id === 'Data') {
    paramsFilter = {
      intervalo: `${dateCustom(filters.dataInicial).data} ${dateCustom(filters.dataFinal).data}`,
    }
  }

  if (filters?.Coluna && filters?.Ordem) {
    paramsFilter = {
      ...paramsFilter,
      coluna: filters?.Coluna,
      ordem: filters?.Ordem,
    }
  }
  return paramsFilter
}

export const listarDadosProjeto = ({
  projetoID, 
  filters={}, 
  typeSelect={}, 
  paginate = {pageNumber: 1, perPage: 7}
}) => {
  
  return dispatch => {
    dispatch(setLoadList(true))
    let paramsFilter = filtersCustom({filters, typeSelect})

    let params = paramsApi({
      projetoId: projetoID,
      paginaAtual: paginate.pageNumber?paginate.pageNumber:1,
      RPP: paginate.perPage?paginate.perPage:7,
      ...paramsFilter,
    })

    api.get(`/Front/GetListaDeLojas${params}`)
    // api.get(`/BaseDados${params}`)
    .then(result => {
      const initialStatePaginate = { 
        pageNumber: result.data.pageAtual, 
        totalPages: result.data.totalPaginas,  
        totalElements: result.data.totalRegistros, 
        perPage: result.data.rpp
      }
      dispatch([
        setListarDadosProjeto(result.data.listaRegistros),
        setPaginateDadosProjeto(initialStatePaginate),
        setInfoTotalAvaliacao(result.data.totalAvaliacao),
        setLoadList(false)
      ])
    })
    .catch(err => {
        console.error(err);
        const initialStatePaginate = { 
          pageNumber: 1, 
          totalPages: 1,  
          totalElements: 0, 
          perPage: paginate.perPage
        }
        dispatch([
          setListarDadosProjeto([]),
          setPaginateDadosProjeto(initialStatePaginate),
          setInfoTotalAvaliacao(0),
          setLoadList(false)
        ])
    });
  }
}

export const filterHandle = ({
  projetoID, 
  params, 
  filtersProvisorio, 
  typeSelect, 
  paginate
}) => {
  const filtersCustom = {...params, ciclo: params?.ciclo?.ano ? params.ciclo : filtersProvisorio.ciclo}
  return dispatch => dispatch([
    filteredItens(params),
    listarDadosProjeto({projetoID, filters: filtersCustom, filtersProvisorio, paginate, typeSelect}),
  ])
}