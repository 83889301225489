import { combineReducers } from 'redux'
import alerts from '../components/alert/alertsRedux'
import layoutState from '../layout/redux/layoutReducer'

import homeState from '../modules/home/redux/homeReducer'

import loginState from '../modules/login/redux/loginReducer'
import basededadosState from '../modules/basededados/redux/basededadosReducer'

import csatclienteState from '../modules/empresa/csatcliente/redux/csatclienteReducer'
import npsclienteState from '../modules/empresa/npscliente/redux/npsclienteReducer'
import rankingclientecsatState from '../modules/empresa/rankingclientecsat/redux/rankingclientecsatReducer'
import rankingclientenpsState from '../modules/empresa/rankingclientenps/redux/rankingclientenpsReducer'
import csatconcorrentesState from '../modules/empresa/csatconcorrentes/redux/csatconcorrentesReducer'
import npsconcorrentesState from '../modules/empresa/npsconcorrentes/redux/npsconcorrentesReducer'

import infoGrupoState from '../modules/grupos/infoGrupo/redux/infoGrupoReducer'
import rankingcsatState from '../modules/grupos/rankingcsat/redux/rankingcsatReducer'
import rankingnpsState from '../modules/grupos/rankingnps/redux/rankingnpsReducer'
import indicadoresState from '../modules/grupos/indicadores/redux/indicadoresReducer'
import swotState from '../modules/grupos/swot/redux/swotReducer'

import comentariosState from '../modules/comentarios/redux/comentariosReducer'

export const rootReducer = combineReducers({
  alerts,
  loginState,
  layoutState,

  homeState,
  basededadosState,

  csatclienteState,
  npsclienteState,
  rankingclientecsatState,
  rankingclientenpsState,
  csatconcorrentesState,
  npsconcorrentesState,

  infoGrupoState,
  rankingcsatState,
  rankingnpsState,
  indicadoresState,
  swotState,

  comentariosState,
})

export default rootReducer
