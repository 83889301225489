import { AddAlert } from '../../../components';
import { history, paramsApi } from '../../../helpers';
import api from '../../../helpers/api';
import apiAuth from '../../../helpers/apiAuth';

export const setSenhaVisivel = e => ({
  type: 'SENHA_VISIVEL',
  payload: e
})

export const setChangeForm = e => ({
  type: 'LOGIN_CHANGE_INFO',
  payload: e
})

export const setChangeEsqueci = e => ({
  type: 'LOGIN_CHANGE_ESQUECI',
  payload: e
})

export const setChangeRecuperar = e => ({
  type: 'LOGIN_CHANGE_RECUPERAR',
  payload: e
})

export const setError = e => ({
  type: 'LOGIN_ERROR',
  payload: e
})

export const enviarEmailRecuperarSenha = ({params}) => {
  let paramsUrl = paramsApi({ 
    ...params
  })
  return async dispatch => {
    apiAuth.post(`/Auth/ForgetPassword${paramsUrl}`)
    .then(result => {
      dispatch(AddAlert('success', 'E-mail enviado com sucesso! veja sua caixa de e-mail.'))
      history.push('/login')
    })
    .catch(err => {
      const {message} = err.response.data
      console.error(err);
      dispatch(AddAlert('danger', message))
    });
  }
}

export const mudarSenhaRecuperarSenha = ({params}) => {
  let paramsUrl = paramsApi({
    ...params
  })
  return async dispatch => {
    apiAuth.post(`/Auth/ResetPassword`, params)
    .then(result => {
      dispatch(AddAlert('success', 'Nova senha cadastrada com sucesso!'))
      history.push('/login')
    })
    .catch(err => {
      const {message} = err.response.data
      console.error(err);
      dispatch(AddAlert('danger', message))
    });
  }
}
