import { AddAlert } from "../../components"
import { cicloIntervalo, paramsApi } from "../../helpers"
import api from "../../helpers/api"
import apiAuth from "../../helpers/apiAuth"
import { history } from "../../helpers/history"
const nls = require('../nls/pt-BR.json')

export const loading = e => ({
  type: 'LOADING',
  payload: true
})
export const loaded = e => ({
  type: 'LOADING',
  payload: false
})

export const setSidebarLeft = e => ({
  type: 'SIDEBAR_LEFT',
  payload: e
})

export const setSidebarRigth = e => ({
  type: 'SIDEBAR_RIGHT',
  payload: e
})

export const modalOpen = e => ({
  type: 'MODAL_OPEN',
  payload: e
})

export const setTitlePage = e => ({
  type: 'TITLE_PAGE',
  payload: e
})

export const validacaoCampos = e => ({
  type: 'VALIDACAO',
  payload: e
})

export const setCarregarUsuarioLogado = e => ({
  type: 'USUARIO_LOGADO_CARREGAR',
  payload: e
})

export const setListarProjetos = e => ({
  type: 'LIST_PROJETOS',
  payload: e
})

export const setInfoProjeto = e => ({
  type: 'INFO_PROJETO',
  payload: e
})

export const setInfoCliente = e => ({
  type: 'INFO_CLIENTE',
  payload: e
})

export const setListarGrupos = e => ({
  type: 'LIST_GRUPOS',
  payload: e
})

export const setInfoGrupo = e => ({
  type: 'INFO_GRUPO',
  payload: e
})

export const setListarEmpresas = e => ({
  type: 'LIST_EMPRESAS',
  payload: e
})

export const setInfoEmpresa = e => ({
  type: 'INFO_EMPRESA',
  payload: e
})

export const setListarCategorias = e => ({
  type: 'LIST_CATEGORIAS',
  payload: e
})

export const setInfoCategoria = e => ({
  type: 'INFO_CATEGORIA',
  payload: e
})

export const setListarCiclos = payload => ({
  type: 'LIST_CICLOS',
  payload
})

export const setInfoCiclo = payload => ({
  type: 'INFO_CICLO',
  payload
})



export const setFilters = payload => ({
  type: 'INFO_FILTERS',
  payload
})

export const setFiltersHas = payload => ({
  type: 'INFO_FILTERS_HAS',
  payload
})

export const setFiltersActive = payload => ({
  type: 'INFO_FILTERS_ACTIVE',
  payload
})

export const cleanFilters = () => ({
  type: 'INFO_FILTERS_CLEAN',
})

export const setFiltered = payload => ({
  type: 'INFO_FILTERED',
  payload
})

export const filteredItens = e => {
  return dispatch => {
    localStorage.setItem('filtered', JSON.stringify(e))
    dispatch(setFiltered(e))
  }
}


export const titlePage = ({pathname, menuItens}) => {
  return dispatch => {
    menuItens?.map(d=> {
      if (d.sub) {
        const activeItem = d.sub.filter(e=> pathname === e.go)
        if (activeItem.length) {
          dispatch(setTitlePage(activeItem[0].label))
        }
      } else {
        if (pathname.includes(d.go)) {
          dispatch(setTitlePage(d.label))
        }
      }
    })
  }
}

export const setProjeto = e => {
  const cicloReturn = e.ciclos.map(c=> cicloIntervalo(c))
  const respGrupos = e.grupos.map((g, i)=> ({id: i, name: g.nomeGrupo?g.nomeGrupo:'grupo', ...g}))
  
  localStorage.setItem('projetoINSV', JSON.stringify(e))
  return dispatch => dispatch([
    setInfoProjeto(e),
    setListarGrupos(respGrupos),
    setInfoGrupo(respGrupos[0]),
    setListarCiclos(cicloReturn),
    setInfoCiclo(cicloReturn[0]),

    setListarEmpresas(e.empresas),
    setInfoEmpresa(e.empresas[0]),
    setFilters({name: 'empresa', value: {}}),
    setFilters({name: 'categoria', value: {}}),

    // setInfoCliente(e.cliente),
    // setListarGrupos(e.grupos),
    // setInfoGrupo(e.grupos[0]),
    // setListarEmpresas(e.empresas),
    // setInfoEmpresa(e.empresas[0]),
    // setListarCategorias(e.categorias),
    // setInfoCategoria(e.categorias[0]),
  ])
}

export const listarProjetos = e => {
  return dispatch => {
    // api.get(`/Projetos/${e}`)
    api.get(`/Usuario/${e}`)
    .then(result => {
      const projetoinsv = JSON.parse(localStorage.getItem('projetoINSV'))
      const projetoUsefy = projetoinsv?.id ? projetoinsv : result.data.projetos[0]
      const cicloReturn = projetoUsefy.ciclos.map(c=> cicloIntervalo(c))
      const respGrupos = projetoUsefy.grupos.map((g, i)=> ({id: i, name: g.nomeGrupo?g.nomeGrupo:'grupo', ...g}))
console.warn(projetoUsefy, 'projetoUsefyprojetoUsefyprojetoUsefyprojetoUsefyprojetoUsefy');
      dispatch([
        setListarProjetos(result.data.projetos),
        setInfoProjeto(projetoUsefy),
        setListarEmpresas(projetoUsefy.empresas),
        setInfoEmpresa(projetoUsefy.empresas[0]),
        setListarGrupos(respGrupos),
        setInfoGrupo(respGrupos[0]),
        setListarCiclos(cicloReturn),
        setInfoCiclo(cicloReturn[0]),
        
        setListarCategorias(result.data.categorias),

        setFiltersHas({name: 'ciclo', value: cicloReturn[cicloReturn.length - 1]})
      ])
    })
    .catch(err => {
        console.error(err, 'listarProjetos');
        dispatch(logOut())
    });
  }
}

export const listarGrupos = e => {
  return dispatch => {
    api.get(`/Grupos${''}`)
    .then(result => {
      const respGrupos = result.data.map((g, i)=> ({id: i, name: g.nomeGrupo?g.nomeGrupo:'grupo', ...g}))
      dispatch(setListarGrupos(respGrupos))
    })
    .catch(err => {
        console.error(err, 'listarProjetos');
    });
  }
}

const setLogIn = e => ({
  type: 'LOG_IN',
  payload: e
})

export const logIn = e => {
  return async dispatch => {
    dispatch(loading())
    const params = {
      email: e.email,
      password: e.senha
    }

    await apiAuth.post(`/Auth/Login`, params)
    .then(response => {
      if (response.data.isSuccess) {
        localStorage.setItem('tolkenINSV', `Bearer ${response.data.message}`)
        dispatch([logUser(e.email)])
      } else {
        dispatch([setLogIn(false), loaded()])
      }
    })
    .catch(error => {
      dispatch([
        AddAlert('danger', 'E-mail e/ou senha invalido(s)'),
        setLogIn(false), 
        loaded()
      ])
    })
  }
}

export const logUser = e => {
  return dispatch => {
    api.get(`/Usuario/${e}`)
    .then(response => {
      dispatch([
        setLogIn(true),
        setCarregarUsuarioLogado(response.data),
        setListarProjetos(response.data.projetos),
        loaded(),
      ])
      localStorage.setItem('userINSV', JSON.stringify(response.data))
      localStorage.setItem('projetosINSV', JSON.stringify(response.data.projetos))
      history.push('/resumoprojeto')
    })
    .catch(error => {
      console.error(error, 'logUser error');
      dispatch(loaded())
    })
  }
}

export const loged = e => {
  const user = localStorage.getItem('userINSV')
  const usuario = JSON.parse(user)
  return dispatch => {
    if (user) {
      dispatch([
        setLogIn(true),
        setCarregarUsuarioLogado(usuario)
      ])
    } else {
      dispatch(logOut())
    }
  }
}

export const logOut = e => {
  localStorage.clear()
  return dispatch => {
    dispatch([
      setLogIn(false), 
      modalOpen(''),  
      setCarregarUsuarioLogado({})
    ])
    history.push('/')
  }
}

export const recuperarSenha = e => {
  return dispatch => {
    api
      .put(`/usuario/recuperarSenha/${e.email}`)
      .then(response => {
        dispatch([
          AddAlert('success', 'Senha enviada para o seu e-mail'),
          history.goBack()
        ])
      })
      .catch(error => {
        dispatch([setLogIn(false), AddAlert('error', nls.mensagem[error.request.response])])
      })
  }
}