import React from 'react'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, IcoEye, IcoEyeBlocked, IcoLogo, IcoPadlock, Input, validacaoForm, validarCampo, validarSenhaConfirmar, verificaValidacao } from '../../components'

import { enviarEmailRecuperarSenha, mudarSenhaRecuperarSenha, setChangeEsqueci, setChangeRecuperar, setError, setSenhaVisivel } from './redux/loginActions'
import './login.scss'
import imgFrame from '../../layout/img/login/frame1.jpg'
import imgLogo from '../../layout/img/logo-custom.png'

export default () => {
  const dispatch = useDispatch()
  const { tolkenRecuperar } = useParams()
  const {usuario} = useSelector(state => state.layoutState)
  const { esqueciSenha, recuperarSenha, senhaVisivel, erro } = useSelector(state=> state.loginState)
  const nls = require('./nls/pt-BR.json')
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsSize = urlParams.size
  const urlParamsRecuperar = {Email: urlParams.get("email"), Token: urlParams.get("token")}
  
  const formRequired = urlParamsSize? { senha: 'senha' }:{ email: 'email' }

  const handleSee = e => {
    dispatch(setSenhaVisivel(e))
  }

  const handleEsqueci = e => {
    dispatch(setChangeEsqueci(e))
    if (formRequired[e.name]) {
      const verify = validarCampo(e)
      dispatch(setError({...erro, [verify.name]: verify.message}))
    }
  }

  const handleRecuperar = e => {
    dispatch(setChangeRecuperar(e))
    if (formRequired[e.name] || e.name === 'senhaConfirma') {
      const verify = validarCampo(e)
      dispatch(setError({...erro, [verify.name]: verify.message}))
    }
  }
  
  const handleEnviarEsquci = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    dispatch(setError(valid))
    const verify = verificaValidacao(valid)
    if (verify.value) {
      dispatch(enviarEmailRecuperarSenha({params:{email: e.email}}))
    }
  }
  
  const handleEnviarRecuperar = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    const validConfirm = validarSenhaConfirmar({value: e.senhaConfirma, senha:e.senha})
    dispatch(setError({...valid, senhaConfirma: validConfirm}))
    const verify = verificaValidacao({...valid, senhaConfirma: validConfirm})
    if (verify.value) {
      dispatch(mudarSenhaRecuperarSenha({params:{...urlParamsRecuperar, NewPassword: e.senha, ConfirmPassword: e.senhaConfirma}}))
    }
  }

  return (
    <div className='box-login'>
      <Alert />
      <div className='box-login-container'>
        <div className='box-login-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-login-form'>
          {
            urlParamsSize?
              <div className='box-login-content'>
                <div className='box-login-logo'>
                  {/* <IcoLogo style={{width: '262px'}} /> */}
                  <img src={imgLogo} />
                </div>
                <div className='box-login-padlock'>
                  <IcoPadlock />
                </div>
                <div className='box-login-info'>
                  <h2>Criar nova senha</h2>
                  <p>Digite a sua nova senha</p>
                </div>
                <Input
                  label='Senha'
                  name='senha'
                  type={!senhaVisivel.senharecuperar?'password':'text'}
                  action={(e) => handleRecuperar(e)}
                  value={recuperarSenha.senha}
                  placeholder='Digite sua nova senha'
                  right={
                    <Button type='link' onClick={()=> handleSee({name: 'senharecuperar', value: !senhaVisivel.senharecuperar})}>
                      {!senhaVisivel.senharecuperar ? <IcoEye />
                      : <IcoEyeBlocked />}
                    </Button>
                  }
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.senha
                  }}
                />
                <Input
                  label='Senha confirmar'
                  name='senhaConfirma'
                  type={!senhaVisivel.senhaConfirma?'password':'text'}
                  action={(e) => handleRecuperar(e)}
                  value={recuperarSenha.senhaConfirma}
                  placeholder='Digite sua nova senha novamente'
                  right={
                    <Button type='link' onClick={()=> handleSee({name: 'senhaConfirma', value: !senhaVisivel.senhaConfirma})}>
                      {!senhaVisivel.senhaConfirma ? <IcoEye />
                      : <IcoEyeBlocked />}
                    </Button>
                  }
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.senhaConfirma
                  }}
                />
                <div className='box-login-action'>
                  {/* <Button
                    type='link'
                    color='primary'
                    onClick={()=> history.push('/login')}
                  >Voltar</Button> */}
                  <Button
                    color='primary'
                    onClick={()=> handleEnviarRecuperar(recuperarSenha)}
                  >Salvar</Button>
                </div>
            </div>
            :
              <div className='box-login-content'>
                <div className='box-login-logo'>
                  {/* <IcoLogo style={{width: '262px'}} /> */}
                  <img src={imgLogo} alt='Logo' />
                </div>
                <div className='box-login-padlock'>
                  <IcoPadlock />
                </div>
                <div className='box-login-info'>
                  <h2>Esqueci minha senha</h2>
                  <p>Digite seu e-mail e enviaremos um link de redefinição</p>
                </div>
                <Input
                  label='E-mail'
                  name='email'
                  action={(e) => handleEsqueci(e)}
                  value={esqueciSenha.email}
                  placeholder='Digite o email cadastrado'
                  required={{
                    erro,
                    message: nls.mensagem,
                    pattern: formRequired.email
                  }}
                />
                <div className='box-login-action'>
                  {/* <Button
                    type='link'
                    color='primary'
                    onClick={()=> history.goBack()}
                  >Voltar</Button> */}
                  <Button
                    color='primary'
                    onClick={()=> handleEnviarEsquci(esqueciSenha)}
                  >Me envie um link</Button>
                </div>
              </div>
            }
        </div>
      </div>
    </div>
  )
}
