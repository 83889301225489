import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { IcoStar, Select } from '../../components'
import './basededados.scss'
import Filter from './filter/filter'
import { filterHandle, listarDadosProjeto } from './redux/basededadosActions'
import Tabela from './tabela'
import { setProjeto } from '../../layout/redux/layoutActions'

export default () => {
  const dispatch = useDispatch()
  const { filters } = useSelector(state=> state.layoutState)
  const { 
    totalAvaliacao, 
    filtersProvisorio, 
    projeto, 
    projetos,
    paginate
  } = useSelector(state=> state.basededadosState)
  
  useEffect(()=> {
    if (projeto.id) {
      if (filters.typeSelect) {
        dispatch(filterHandle({projetoID: projeto.id, params: filters, filtersProvisorio, typeSelect: filters.typeSelect, paginate}))
      } else {
        dispatch(listarDadosProjeto({projetoID: projeto.id, filters: {ciclo: projeto.ciclo[0]}, typeSelect: { id: "Ciclo", text: "Filtrar por Ciclo" }}))
      }
    }
  }, [projeto])

  const handleFormFilter = (e) => {
    dispatch(setProjeto(e.value))
  }
  
  return (
    <div className='box-basededados'>
      <div className='box-basededados-topinfo'>
        <div>
          <div className='box-basededados-topinfo-select'>
            <Select 
              name='projeto'
              label='Projeto:'
              options={projetos}
              selected={projeto}
              action={(e)=> handleFormFilter(e)}
              selectedItem={false}
              optionLabel='nomeProjeto'
              optionValue='id'
            />
          </div>
          <div className='box-csatcliente-topinfo-select'>
            {/* <label>Cliente: </label> 
            <span> {projeto?.empresa}</span> */}
          </div>
        </div>
        <div className='box-basededados-topinfo-avaliacao'>
            <label>Número de avaliações:</label>
            <IcoStar />
            <strong>{totalAvaliacao?totalAvaliacao:0}</strong>
        </div>
      </div>
      <Filter />
      <Tabela />
    </div>
  )
}
